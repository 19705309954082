<template>
    <section>
        <h2>{{ $t('contact.title') }}</h2>
        <p class="m-0">
            {{ $t('email') }}: <a :href="`mailto:${contact}`">{{ contact }}</a>
        </p>
        <div class="mt-4">
            <h3>{{ $t('contact.form') }}</h3>
            <div class="p-3 border border-dark rounded">
                <p class="text-muted">
                    {{ $t('contact.header') }}
                </p>
                <ContactForm />
            </div>
        </div>
    </section>
</template>

<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import ContactForm from '../components/lib/ContactForm.vue'

const store = useStore()
const contact = computed(() => store.getters.contactEmail)
</script>
