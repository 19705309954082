import {createStore} from 'vuex'
import {parse} from 'yaml'
import {Sessions} from './lib/Session.js'
import {HttpClient} from './lib/HttpClient.js'
import {Objects} from './lib/Objects.js'
import {Contents} from './lib/Contents.js'
import {StoreCache} from './lib/StoreCache.js'
import {VideoProgressStore} from './store/VideoProgressStore.js'
import {SubtitlesStore} from './store/MoviesStore.js'

export class Store {
    store

    constructor(internationalization) {
        const {locale, fallbackLocale, translation} = internationalization

        const staticsCache = new StoreCache()
        const store = createStore({
            modules: {
                VideoProgressStore,
                SubtitlesStore,
            },
            state: {
                homeReady: false,
                httpClient: new HttpClient(),
                sessionId: undefined,
                locale,
                fallbackLocale,
                ...(Sessions.restore() || Sessions.initialize()),
                availableLocales: [],
                translations: !translation ? {} : {[locale]: translation},
                contents: {},
                videos: {},
                movies: {},
                privacyPolicy: {},
                imprint: {},
                contactEmail: 'info@vegan-empire.com',
            },
            actions: {
                setLocale: ({dispatch, commit}, locale) => Sessions.persist({locale}) && Promise
                    .all([
                        dispatch('loadTranslation', locale),
                        dispatch('loadContents', locale),
                    ])
                    .then(() => {
                        internationalization.setLocale(locale)
                        commit('setLocale', locale)
                    })
                    .catch(console.error),
                loadAvailableLocales: ({getters: {httpClient}, commit}) => staticsCache.cached(`content/locales.yml`, url => httpClient
                    .get(url)
                    .then(parse)
                    .then(locales => commit('setAvailableLocales', locales))
                    .catch(console.error),
                ),
                loadTranslation: ({getters: {httpClient}, commit}, locale) => staticsCache.cached(`content/${locale}/translation.yml`, url => httpClient
                    .get(url)
                    .then(parse)
                    .then(translation => internationalization
                        .addTranslation(locale, translation)
                        .then(() => commit('setTranslation', {locale, translation})),
                    )
                    .catch(console.error),
                ),
                loadContents: ({getters: {httpClient}, commit}, locale) => staticsCache.cached(`content/${locale}/content.yml`, url => httpClient
                    .get(url)
                    .then(parse)
                    .then(contents => commit('setContents', {locale, contents}))
                    .catch(console.error),
                ),
                loadMovies: ({getters: {httpClient}, commit}, locale) => Promise
                    .all([
                        staticsCache.cached('content/movies.yml', url => httpClient
                            .get(url)
                            .then(parse)
                            .then(sources => sources.reduce((byKey, {key, sources}) => ({...byKey, [key]: sources}), {})),
                        ),
                        staticsCache.cached(`content/${locale}/movies.yml`, url => httpClient
                            .get(url)
                            .then(parse),
                        ),
                    ])
                    .then(([sourcesByKey, movies]) => movies.map(({key, ...movie}) => ({
                        ...movie,
                        key,
                        sources: sourcesByKey[key],
                    })))
                    .then(movies => commit('setMovies', {locale, movies}))
                    .catch(console.error),
                loadPrivacyPolicy: ({getters: {httpClient}, commit}, locale) => staticsCache.cached(`content/${locale}/privacy-policy.md`, url => httpClient
                    .get(url)
                    .then(privacyPolicy => commit('setPrivacyPolicy', {locale, privacyPolicy}))
                    .catch(console.error),
                ),
                loadImprint: ({getters: {httpClient}, commit}, locale) => staticsCache.cached(`content/${locale}/imprint.md`, url => httpClient
                    .get(url)
                    .then(imprint => commit('setImprint', {locale, imprint}))
                    .catch(console.error),
                ),
                sendContactMessage: ({commit, getters: {httpClient, contacts}}, message) => httpClient
                    .post('api/contact', message, HttpClient.JSON_HEADER)
                    .then(() => {
                        const contactTimes = [...(contacts || []), new Date().getTime()]
                        Sessions.persist({contacts: contactTimes})
                        commit('setContacts', contactTimes)
                    }),
            },
            mutations: {
                setHomeReady: (state, homeReady) => state.homeReady = homeReady,
                setLocale: (state, locale) => state.locale = locale,
                setAvailableLocales: (state, availableLocales) => state.availableLocales = availableLocales,
                setTranslation: (state, {locale, translation}) => state.translations[locale] = translation,
                setContents: (state, {locale, contents}) => {
                    state.contents[locale] = contents
                    state.videos[locale] = Contents.findVideos(contents)
                },
                setMovies: (state, {locale, movies}) => state.movies[locale] = movies,
                setPrivacyPolicy: (state, {locale, privacyPolicy}) => state.privacyPolicy[locale] = privacyPolicy,
                setImprint: (state, {locale, imprint}) => state.imprint[locale] = imprint,
                setContacts: (state, contacts) => state.contacts = contacts,
            },
            getters: {
                homeReady: ({homeReady}) => homeReady,
                httpClient: ({httpClient}) => httpClient,
                sessionId: ({sessionId}) => sessionId,
                locale: ({locale}) => locale,
                fallbackLocale: ({fallbackLocale}) => fallbackLocale,
                availableLocales: ({availableLocales}) => availableLocales,
                translation: ({translations}) => locale => translations[locale],
                contents: ({contents}) => locale => contents[locale],
                videos: ({videos}) => locale => videos[locale],
                movies: ({movies}) => locale => movies[locale],
                privacyPolicy: ({privacyPolicy}) => locale => privacyPolicy[locale],
                imprint: ({imprint}) => locale => imprint[locale],
                contactEmail: ({contactEmail}) => contactEmail,
                contacts: ({contacts}) => contacts,
                languageName: () => locale => internationalization.getLanguageName(locale),
            },
        })

        this.addModules = modules => Object
            .entries(modules)
            .filter(([name]) => !store.hasModule(name))
            .map(([name, module]) => store.registerModule(name, module))
            .length && document.dispatchEvent(new CustomEvent('app-rerender'))

        Objects.setProperties(this, {store})
    }
}
